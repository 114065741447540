<template>
	<div class="max-w-xl m-auto">
		<div class="pb-8 text-center">
			<h1 class="pb-3 text-2xl font-bold md:text-3xl">
				Create an account
			</h1>
			<p class="p-2 text-base md:text-xl md:p-0">
				Signing up for Where's Weed is fast and free.
				<br>
				Already a member?
				<router-link
					:to="{ name: 'login' }"
					class="text-green-500"
				>
					Sign In
				</router-link>
			</p>
		</div>
		<form @submit.prevent="register">
			<div class="grid w-full grid-cols-1 gap-4 text-left md:grid-cols-2">
				<div
					v-for="field in textInputs"
					:key="field.id"
					class="w-full"
				>
					<WwTextInput
						:id="field.id"
						v-model="field.value"
						input-class="w-full"
						:required="field.required"
						:label="field.label"
						:input-type="field.type"
						:value="field.value"
						:autocomplete="field.autocomplete"
						:error-message="field.errorMessage"
						:state="handleState(textInputs, field)"
					/>
				</div>
			</div>

			<div class="py-3 text-sm text-left text-gray-500">
				<div class="font-bold uppercase">
					<input
						v-model="nonTextInputs.newsletter"
						type="checkbox"
						value="1"
						class="text-green-500 border-gray-300 rounded-sm focus:ring focus:ring-green-300 focus:border-none"
					> Subscribe to our Newsletter
				</div>
				<div class="py-1 text-xs">
					We <u>
						never
					</u> sell or give out your email, and you can unsubscribe no hassle at any time!
				</div>
			</div>

			<div class="py-3 text-sm font-bold text-left text-gray-500 uppercase">
				<input
					v-model="nonTextInputs.i_agree"
					type="checkbox"
					value="true"
					class="text-green-500 border-gray-300 rounded-sm focus:ring focus:ring-green-300 focus:border-none"
					required
				> I Agree to the <router-link
					class="text-green-500"
					:to="{ name: 'terms'}"
				>
					Terms of Service
				</router-link> & <router-link
					class="text-green-500"
					:to="{ name: 'privacy'}"
				>
					Privacy Policy
				</router-link>
			</div>
			<button
				type="submit"
				:disabled="!formIsValid"
				:class="formIsValid ? 'bg-green-500' : 'bg-green-300 cursor-not-allowed'"
				class=" text-white w-full p-2.5 rounded uppercase font-bold my-3"
			>
				Register
			</button>
		</form>
		<ClientOnly>
			<SocialLoginButtons class="w-full mx-auto" />
		</ClientOnly>
	</div>
</template>

<script async>
import ClientOnly from 'vue-client-only'
import { mapMutations } from 'vuex'

import WwTextInput from '@/components/UI/WwTextInput.vue'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { DATE, EMAIL, NUMBER, PASSWORD, TEXT } from '@/constants/text-inputs/types.js'
import tempModule from '@/store/modules/tempModule.js'
import { inputFieldState } from '@/validators/text-inputs.js'

export default {
	components: {
		SocialLoginButtons: () => import('@/components/login/SocialLoginButtons.vue'),
		ClientOnly,
		WwTextInput
	},
	props: {
		routeFrom: {
			type: String,
			default: ''
		},
		isModal: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			textInputs: [
				{ id: 'username', value: '', required: true, label: 'Username', autocomplete: 'username', type: TEXT, errorMessage: 'Username is required' },
				{ id: 'email', value: '', required: true, label: 'Email', autocomplete: 'email', type: EMAIL, errorMessage: 'Email is required' },
				{ id: 'first_name', value: '', required: true, label: 'First Name', autocomplete: 'given-name', type: TEXT, errorMessage: 'First Name is required' },
				{ id: 'last_name', value: '', required: true, label: 'Last Name', autocomplete: 'family-name', type: TEXT, errorMessage: 'Last Name is required' },
				{ id: 'password', value: '', required: true, label: 'Password', autocomplete: 'new-password', type: PASSWORD, errorMessage: 'Password is required' },
				{ id: 'password_again', value: '', required: true, label: 'Verify Password', autocomplete: 'new-password', type: PASSWORD, errorMessage: 'Passwords do not match' },
				{ id: 'dob', value: '', required: true, label: 'Date of Birth', autocomplete: 'bday', type: DATE, errorMessage: 'Date of Birth is required' },
				{ id: 'zip', value: '', required: true, label: 'Zip', autocomplete: 'postal-code', type: NUMBER, errorMessage: 'Zip is required' }
			],
			nonTextInputs: {
				text_specials: '',
				cell: '',
				newsletter: 0,
				i_agree: false
			},
			EMAIL,
			PASSWORD,
			NUMBER,
			DATE,
			inputFieldState
		}
	},
	computed: {
		formIsValid() {
			return !this.textInputs
				.filter(field => field.required)
				.some(field => inputFieldState(field.value, field.type) === INPUT_STATES.ERROR) &&
				!!this.nonTextInputs.i_agree &&
				this.textInputs.find(field => field.id === 'password')?.value === this.textInputs.find(field => field.id === 'password_again')?.value
		},
		tempData() {
			return this.$store.state.temp
		},
		formData() {
			const obj = {}
			const assignField = (field) => { obj[field.id] = field.value }
			this.textInputs.forEach(field => assignField(field))
			Object.assign(obj, this.nonTextInputs)
			return obj
		}
	},
	watch: {
		tempData: {
			handler: function () {
				if (this.tempData?.firstName) this.setTextInputFromTemp('first_name', this.tempData.firstName)
				if (this.tempData?.lastName) this.setTextInputFromTemp('last_name', this.tempData.lastName)
				if (this.tempData?.dob) this.setTextInputFromTemp('dob', this.tempData.dob)
				if (this.tempData?.zip) this.setTextInputFromTemp('zip', this.tempData.zip)
			},
			immediate: true
		}
	},
	beforeCreate() {
		this.$store.registerModule('temp', tempModule)
	},
	methods: {
		handleState(textInputs, field) {
			if (field.id === 'password_again' && field.value !== textInputs.find(input => input.id === 'password').value) {
				return INPUT_STATES.ERROR
			} else {
				return inputFieldState(field.value, field.type)
			}
		},
		setTextInputFromTemp (key, value) {
			if (this.textInputs.find(input => input.id === key)) {
				this.textInputs.find(input => input.id === key).value = value
			}
		},
		...mapMutations('modal', [ 'closeModal' ]),
		register: async function (/* event */) {
			const getData = {
				type: 'User',
				func_name: 'register',
				setResults: false,
				data: this.formData
			}

			/* let returnData = */ await this.$store.dispatch('apiCall', getData, this.$store)
			// let newUser = returnData.data.results.new_user

			if (this.$store.state.auth.loggedIn) {
				if (this.isModal) {
					this.closeModal()
				} else {
					if (this.$route.name === 'register') {
						this.$router.push({ name: 'userIndex', params: { user: this.$store.state.auth.username } })
					} else {
						this.$router.push(this.routeFrom)
					}
				}
			}
		}
	}
}
</script>
